<template>
  <div class="app-container"  style="height: 100%">
    <el-container style="height: 100%">
      <el-header class="header">
        <div ref="headerQuery">
          <el-row class="headerClass" >
            <el-col :span="4" class="title">药品管理</el-col>
            <el-col :span="20">
              <el-form :model="queryParams" ref="queryForm"  class="query-form" :inline="true" style="float: right">


                <el-form-item label="" style="width: 200px;" class="el-item-dropdown-300">
                  <el-select
                    :popper-append-to-body="false"
                    v-model="queryParams.substanceIds"
                    filterable
                    size="small"
                    remote
                    multiple
                    collapse-tags
                    reserve-keyword
                    placeholder="成分物质名称"
                    :remote-method="remoteMethodSL"
                    :loading="loading">
                    <el-option
                      v-for="item in substanceList"
                      :key="item.substanceId"
                      :label="item.name"
                      :value="item.substanceId">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="" style="width: 200px;" class="el-item-dropdown-300">
                  <el-autocomplete
                    :popper-append-to-body="false"
                    v-model="queryParams.drugName"
                    :fetch-suggestions="queryDrugName"
                    placeholder="药物名称"
                    @select="handleQuery"
                    @clear="setBlur()"
                    clearable
                    size="small"
                    value-key="name"
                    style="height: 36px!important;line-height: 36px;"
                    @keydown.enter.native="handleQuery"
                  >
                    <el-select v-model="queryParams.drugNameSearchType"
                               style="width: 74px;"
                               slot="prepend" placeholder="请选择">
                      <el-option label="模糊" value="1"></el-option>
                      <el-option label="精确" value="2"></el-option>
                      <el-option label="前缀" value="3"></el-option>
                    </el-select>
                  </el-autocomplete>
                </el-form-item>

                <el-form-item label="" style="width: 200px;" class="el-item-dropdown-300">
                  <el-autocomplete
                    :popper-append-to-body="false"
                    v-model="queryParams.preparationName"
                    :fetch-suggestions="queryPreparationName"
                    placeholder="制剂名称"
                    @select="pickPreparationName"
                    @clear="setBlur()"
                    clearable
                    size="small"
                    value-key="name"
                    style="height: 36px!important;line-height: 36px;"
                    @keydown.enter.native="handleQuery"
                  >
                    <el-select v-model="queryParams.preparationNameSearchType"
                               style="width: 74px;"
                               slot="prepend" placeholder="请选择">
                      <el-option label="模糊" value="1"></el-option>
                      <el-option label="精确" value="2"></el-option>
                      <el-option label="前缀" value="3"></el-option>
                    </el-select>
                  </el-autocomplete>
                </el-form-item>
                <el-form-item label="" style="width: 200px;" v-show="queryParams.preparationId">
                  <!--            <el-input placeholder="制剂规格描述" v-model="queryParams.specDesc"
                                        style="height: 36px!important;line-height: 36px;"
                              />-->
                  <el-select
                    v-model="queryParams.specId"
                    placeholder="制剂规格"
                    size="small"
                    style="width: 150px"
                    clearable
                  >
                    <el-option
                      v-for="item in preparationSpecList"
                      :key="item.specId+'preparationSpec'+item.specDesc"
                      :label="item.specDesc"
                      :value="item.specId+''"
                    />
                  </el-select>
                </el-form-item>


                <el-form-item label="" style="width: 220px;" class="el-item-dropdown-300">
                  <el-autocomplete
                    :popper-append-to-body="false"
                    v-model="queryParams.searchValue"
                    :fetch-suggestions="querySearchAsync"
                    placeholder="药品ID/名称/批准文号/本位码"
                    @select="handleQuery"
                    @clear="setBlur()"
                    clearable
                    size="small"
                    value-key="alias"
                    style="height: 36px!important;line-height: 36px;"
                    @keydown.enter.native="handleQuery"
                  >
                    <el-select v-model="queryParams.searchValueSearchType"
                               style="width: 74px;"
                               slot="prepend" placeholder="请选择">
                      <el-option label="模糊" value="1"></el-option>
                      <el-option label="精确" value="2"></el-option>
                      <el-option label="前缀" value="3"></el-option>
                    </el-select>
                  </el-autocomplete>
                </el-form-item>

                <el-form-item label="" style="width: 120px;" class="el-item-dropdown-300">
                  <el-input v-model="queryParams.pkgDrugProdId" size="small" type="number"
                            placeholder="包装药品id" maxlength="30"></el-input>
                </el-form-item>

                <el-form-item label="" style="width: 200px;" class="el-item-dropdown-300">
                  <el-select
                    v-model="queryParams.manufacturerId"
                    size="small"
                    filterable
                    remote
                    clearable
                    reserve-keyword
                    placeholder="请输入厂家/上市许可持有人名称"
                    :remote-method="queryManufacturer"
                    :loading="loading"
                  >
                    <el-option
                      v-for="item in manufacturerList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.alias.toString() }}</span>
                    </el-option>
                  </el-select>
                </el-form-item>


                <el-form-item label="" style="width: 220px;" class="el-item-dropdown-300">
                  <el-input
                    v-model="queryParams.nhsaOrSmpaCode"
                    placeholder="国家贯标码/上海统编码"
                    @clear="setBlur()"
                    maxlength="40"
                    clearable
                    size="small"
                    style="height: 36px!important;line-height: 36px;"
                    @keydown.enter.native="handleQuery"
                  >
                  </el-input>
                </el-form-item>



                <el-form-item label="" prop="status">
                  <el-select
                    v-model="queryParams.status"
                    placeholder="状态"
                    size="small"
                    class="statusClass"
                    style="width: 74px"
                    clearable
                  >
                    <el-option
                      v-for="dict in statusOptions"
                      :key="dict.conceptId"
                      :label="dict.conceptVal"
                      :value="dict.conceptId"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery">检索</el-button>
                  <el-button icon="el-icon-circle-plus-outline" size="small" class="addClass" @click="handleAdd">新增药品</el-button>
                  <el-button icon="fa fa-cog" size="small" @click="columnSettings">设置</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-table border stripe height="100%" v-loading="loading"
                  :data="drugList" row-key="id" :expand-row-keys="drugTableExpandRowIds"
                  @expand-change="expandTableRow" ref="refTable"
                  :cell-class-name="drugProdTableCellClass"
                  :row-class-name="drugProdTableRowClass"
                  @sort-change="tableSortChange"
                  @row-contextmenu="rowContextmenu"
        >
          <el-table-column type="expand" width="45" fixed="left">
            <template slot-scope="props">
              <el-table
                border
                stripe
                :header-cell-style="{'text-align':'center'}"
                :cell-style="{'text-align':'center'}"
                :data="props.row.dkbPdProdList" v-loading="loading" :row-class-name="drugProdTableRowClass">
                <el-table-column label="包装药品ID" width="90" prop="id"/>
                <el-table-column label="包装类型" width="80" prop="packTypeDesc"/>
                <el-table-column label="包装药品名称" width="400">
                  <template slot-scope="scope">
                    <span style="margin-right: 5px;">{{scope.row.drugName}}</span>
                    <span v-for="item in scope.row.drugCategoryList"
                          :key="item.id"
                    >
                    <svg-icon :icon-class="item.icon" v-if="item.icon"/>
                  </span>
                  </template>
                </el-table-column>
                <el-table-column label="包装数量" width="150">
                  <template slot-scope="scope">
                    {{ scope.row.packQty }}
                    {{ scope.row.specUnitDesc }}
                    <span style="font-size: 18px;font-weight: bold;">/</span>
                    {{ scope.row.packQtyUnitDesc }}
                  </template>
                </el-table-column>
                <el-table-column label="包装规格描述" width="400" prop="packSpecDesc"/>
                <!--              <el-table-column
                                label="有效期"
                                width="80"
                              >
                                <template slot-scope="scope">
                                  {{ scope.row.indate }} {{ scope.row.indateUnitDesc }}
                                </template>
                              </el-table-column>-->
                <el-table-column label="包装材料" width="200">
                  <template slot-scope="scope">
                    <P style="text-align: left" v-for="(item,index) in scope.row.pdProdMatList" :key="item.id+'pdProdMatList'">
                      {{'['+(index+1)+']'}}{{ item.wrapperDesc}}
                      <span v-show="!isEmpty(item.stateMedicareNum)" style="margin-left: 10px;">国家医保代码:{{ item.stateMedicareNum}}</span>
                      <span v-show="!isEmpty(item.shMedicareNum)" style="margin-left: 10px;">上海医保代码:{{ item.shMedicareNum}}</span>
                      <span v-show="index<scope.row.pdProdMatList.length-1">;</span>
                    </P>
                  </template>
                </el-table-column>
                <template slot="empty">
                  <img src="@/assets/images/no-booking.svg" alt="">
                  <p>暂无数据</p>
                </template>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column
            v-for="data in columnOptions"
            :key="data.id"
            :prop="data.prop"
            :label="data.name"
            :fixed="data.fixed?data.fixed:false"
            :resizable="false"
            :sortable="data.sortable?data.sortable:false"
            :min-width="data.width?data.width:'80'"
            :align="data.align?data.align:'center'"
          >
            <template slot-scope="scope">
              <div v-if="data.prop == 'preparationSpecDesc'">
                <!--            <p class="setTwoLine" v-html="scope.row.preparationSpecDesc"></p>-->
                <span class="setTwoLine">{{ scope.row[data.prop] }}</span>
              </div>
              <div v-else-if="data.prop == 'specDesc'">
                <!--            <p class="setTwoLine" v-html="scope.row.specDesc"></p>-->
                <span class="setTwoLine">{{ scope.row[data.prop] }}</span>
              </div>
              <div v-else-if="data.prop == 'manufacturerName'">
                <span  v-for="item in scope.row.dkbDpManufacturerList" style="padding-right: 6px;display: inline-block;">
                  <el-tag size="mini">{{item.manufacturerTypeSimpleDesc}}</el-tag>
                  {{item.manufacturerName}}</span>
              </div>
              <div v-else-if="data.prop == 'alias'">
                <span style="margin-right: 5px;">{{ scope.row[data.prop] }}</span>
                <span v-for="item in scope.row.drugCategoryList" :key="item.id">
              <svg-icon :icon-class="item.icon" v-if="item.icon"/>
            </span>
              </div>
              <div v-else>
                <span>{{ scope.row[data.prop] }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" key="status" width="100" fixed="right">
            <template slot-scope="scope">
              <el-switch
                class="switch"
                active-color="#5DB730"
                active-text='启用'
                inactive-text='停用'
                inactive-color="#B5CCF5"
                v-model="scope.row.status"
                active-value="0"
                inactive-value="1"
                @change="handleStatusChange(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <template slot="empty" v-if="isResult === false">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
          <template slot="empty" v-else>
            <img src="@/assets/images/no-left-data.svg" alt="">
            <p>未查询到结果，请修改查询条件重试！</p>
          </template>
        </el-table>

      </el-main>
      <el-footer class="footer">
        <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="selectDrugProdList"
          :page-sizes="[10, 20, 50, 100]"
        />

      </el-footer>
    </el-container>








    <table-context-menu v-if="tableContextMenuVisible"
                        @foo="foo" ref="contextbutton"
                        :action="tableAction"
                        @handleTableAction="handleTableAction"></table-context-menu>



    <el-dialog
      title="提示"
      v-dialogDrag
      :visible.sync="statusDialogVisible"
      width="420px" :before-close="statusDialogClose"
    >
      <div class="el-message-box__message">
        <p v-show="editRow && editRow.status==='0'">确认要启用药品吗?</p>
        <p v-show="editRow && editRow.status==='1'">确认要禁用药品吗?</p>
      </div>
      <br>
      <div v-show="editRow && editRow.status==='0'">
        <el-radio v-model="activePkgDrugProd" label="1" border>启用包装药品</el-radio>
        <el-radio v-model="activePkgDrugProd" label="0" border>不启用包装药品</el-radio>
      </div>

      <span slot="footer" class="dialog-footer">
    <el-button @click="statusDialogClose">取 消</el-button>
    <el-button type="primary" @click="statusDialogConfirm" style="margin-right: 20px;">确 定</el-button>
  </span>
    </el-dialog>


    <el-dialog
      v-dialogDrag
      :title="formDisplayData.title"
      :visible.sync="dpEditDialogVisible" :before-close="closeDpEditDialog"
      width="70%" :close-on-click-modal="false"
    >
      <!--      <edit   :dkbDpData="{id:1212,dkbDpAliasList:[1,2,3]}"/>-->
      <edit :dkbDpData="dkbDpData" :formDisplayData="formDisplayData" @editFinish="handleDpEditFinish"
            @edit="handleDpEdit"
      />
    </el-dialog>


    <el-dialog v-dialogDrag title="引用来源管理" :visible.sync="resourceTableVisible">
      <el-row>
        <el-col :span="24" style="margin-bottom: 10px;">
          <el-button style="float: right;margin-right: 10px;" icon="el-icon-circle-plus-outline"
                     size="small" @click="addResource"
          >添加
          </el-button>
        </el-col>
      </el-row>
      <el-form ref="resourceTableForm" status-icon :model="resourceTableForm">
        <el-table
          border
          stripe
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
          :data="resourceTableForm.list">
          <el-table-column property="date" label="引用资源类型" align="center" width="180">
            <template slot-scope="scope">
              <el-form-item
                :prop="'list.' + scope.$index + '.'+'resourceTypeId'"
                :rules="[{ required: true, message: '请选择物质名称', trigger: 'blur'}]"
              >
                <el-select
                  v-model="scope.row.resourceTypeId"
                  placeholder="引用资源类型"
                  size="small"
                  style="width: 150px"
                  @change="resourceTypeChange($event,scope.$index)"
                >
                  <el-option
                    v-for="dict in resourceTypeOption"
                    :key="'resourceTypeOption'+dict.id"
                    :label="dict.typeName"
                    :value="dict.id"
                  />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column property="name" align="center" label="引用资源名称">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.resourceId"
                filterable
                remote
                style="width:300px"
                reserve-keyword
                placeholder="请输入关键词"
                @change="resourceNameChange($event,scope.$index)"
                :remote-method="(query)=>{resourceNameQuery(query,scope.row.resourceTypeId)}"
                :loading="loading"
                :disabled="!scope.row.resourceTypeId?true:false"
              >
                <el-option
                  v-for="item in resourceDataOption"
                  :key="'resourceDataOption'+item.id"
                  :label="item.name"
                  :value="item.id"
                />
                <el-option
                  v-for="item in [scope.row]"
                  :key="item.resourceId+'original'"
                  :label="item.resourceName"
                  :value="item.resourceId"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="100">
            <template slot-scope="scope">

              <el-button
                size="mini"
                type="text"
                style="color: #FF3333;"
                @click="handleResourceDelete(scope.$index,scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <img src="@/assets/images/no-booking.svg" alt="">
            <p>暂无数据</p>
          </template>
        </el-table>
      </el-form>
      <el-row>
        <el-col :span="24" style="margin-top: 10px;">
          <el-button type="primary" style="float: right;margin-right: 10px;"
                     size="small" @click="saveResource"
          >保存
          </el-button>
          <el-button style="float: right;margin-right: 10px;"
                     size="small" @click="resourceTableVisible=false"
          >取消
          </el-button>
        </el-col>
      </el-row>
    </el-dialog>



    <drug-category-edit ref="drugCategoryEdit"></drug-category-edit>
  </div>
</template>

<script>
import { list, info, updateStatus, selectDrugProdNameList, del, copy } from '@/api/dkm/drugProd'
import conceptConst from '@/const/conceptConst'
import { getConcept } from '@/utils/concept'
import { GLOBAL_CONFIGURATION_CONFIGKEY } from '@/utils/constants'
import { UCgetConfig } from '@/utils/columnSettings'
import edit from './edit'
import {  selectSubstanceNameList } from '@/api/dkm/substance'
import { isEmpty } from '@/utils/common'
import { getResourceCategory, getReferenceInfo } from '@/utils/resourceReference'
import { selectReferenceInfo, saveOrUpdateInfoList, resourceNameQuery } from '@/api/dkm/resource'
import tableConst from '@/const/tableConst'
import { selectDrugNameList } from '@/api/dkm/drugGroup'
import { selectDrugNameList as selectPreparationNameList,getSpecInfo } from '@/api/dkm/preparationSpecification'
import { setCache, getCache } from '@/utils/cacheUtil'
import AcrossUtil from '@/utils/acrossUtil'
import { selectManufacturerNameByKey } from '@/api/dkm/manufacturer'

export default {
  name: 'DrugProd',
  components: { edit },
  watch: {
    /*监听制剂名称值的变化*/
    'queryParams.preparationName': {
      deep: true,
      handler(newVal, oldVal) {
        //制剂名称空的时候，隐藏制剂规格检索框
        if(isEmpty(newVal)){
          this.queryParams.preparationId=undefined
          this.specId=undefined
          this.specDesc=undefined
          this.preparationSpecList=[]

        }
      }
    },
  },
  data() {
    return {
      conceptConst,
      tableConst,
      isResult: false,
      substanceList: [],
      /*药品启用禁用弹窗*/
      statusDialogVisible: false,
      /*药品各个属性编辑弹窗*/
      dpEditDialogVisible: false,
      /*药品启用禁用弹窗中的单选--是否自动启用所有关联的包装药品*/
      activePkgDrugProd: '1',
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        searchValue: undefined,
        searchValueSearchType:"1",
        drugName:undefined,
        drugNameSearchType:"1",
        preparationId:undefined,
        preparationName:undefined,
        preparationNameSearchType:"1",
        specId:undefined,
        specDesc:undefined,
        substanceIds:[],
        orderByColumn:undefined,
        isAsc:undefined,
      },
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      // 药品表格数据
      drugList: [],
      // 药品表格 展开行id
      drugTableExpandRowIds:[],
      /*单个药品明细数据*/
      dkbDpData: {},
      /*当前编辑行数据*/
      editRow: undefined,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 状态数据字典
      statusOptions: [],
      /*药品列表-表格参数配置*/
      tableOptions: [
        {
          id: 1,
          prop: 'id',
          name: '药品ID',
          show: true,
          sortable:'custom',
          align: 'center',
          width: '100'
        },
        {
          id: 2,
          prop: 'drugName',
          name: '药物名称',
          show: true,
          sortable: false,
          align: 'center',
          width: '200'
        },
        {
          id: 3,
          prop: 'preparationName',
          name: '制剂名称',
          show: true,
          sortable: false,
          align: 'center',
          width: '200'
        },
        {
          id: 4,
          prop: 'preparationForm',
          name: '制剂剂型',
          show: true,
          sortable: false,
          align: 'center',
          width: '100'
        },
        {
          id: 5,
          prop: 'preparationSpecDesc',
          name: '制剂规格描述',
          show: true,
          width: '400'
        },
        {
          id: 6,
          prop: 'drugClassify',
          name: '中西草标志',
          show: true,
          sortable: false,
          align: 'center',
          width: '100'
        },
        {
          id: 7,
          prop: 'domesticOrImportDesc',
          name: '进口/国产标志',
          show: true,
          sortable: false,
          align: 'center',
          width: '120'
        },
        {
          id: 11,
          prop: 'alias',
          name: '药品名称',
          show: true,
          sortable: false,
          align: 'center',
          width: '300'
        },
        {
          id: 12,
          prop: 'standardCode',
          name: '本位码',
          show: true,
          sortable: false,
          align: 'center',
          width: '180'
        },
        {
          id: 13,
          prop: 'approvalNum',
          name: '批准文号',
          show: true,
          sortable: false,
          align: 'center',
          width: '180'
        },
        {
          id: 14,
          prop: 'manufacturerName',
          name: '生产企业',
          show: true,
          sortable: false,
          align: 'center',
          width: '300'
        },
        {
          id: 15,
          prop: 'mahName',
          name: '上市许可持有人',
          show: true,
          sortable: false,
          align: 'center',
          width: '120'
        },
        {
          id: 16,
          prop: 'specDesc',
          name: '规格描述',
          show: true,
          sortable: false,
          align: 'center',
          width:'400',
          'min-width': '150'
        },
        {
          id: 17,
          prop: 'updateTime',
          name: '更新时间',
          fixed: false,
          show: true,
          sortable:  'custom',
          align: 'center',
          width: '150'
        }
      ],
      sltConfigKey: '', // 药品管理-药品列表-表格配置key名称
      sltConfigClassify: '',// 药品管理-药品列表-配置分类
      columnOptions: [],// 药品管理-药品列表-表格配置
      /*控制编辑页面各个模块表单的显示与隐藏*/
      formDisplayData: {
        base: true,
        alias: true,
        approvalNum: true,
        specDes: true,
        specIngredient: true,
        pdProd: true,
        title: ''

      },
      /* 标志数据是否编辑过*/
      editFlag:false,
      /* 控制引用来源表格弹窗显示隐藏*/
      resourceTableVisible: false,
      /*来源类型下拉列表*/
      resourceTypeOption: [],
      resourceDataOption: [],
      /*当前操作表格行数据*/
      editTableRow: [],
      /* 来源引用表格数据*/
      resourceTableForm: {
        list: []
      },
      /*表格右键菜单显示隐藏*/
      tableContextMenuVisible:false,
      tableAction:[
        {name:"修改"},
        {name:"复制"},
        {name:"别名"},
        {name:"批准文号"},
        {name:"成分"},
        {name:"包装"},
        {name:"来源"},
        {name:"删除",style:'color: #FF3333;'},
        {name:"分类维护"},
        {name: '查看'}
      ],
      /*制剂规格下拉列表数据*/
      preparationSpecList:[],
      /*厂家名称下拉列表搜索*/
      manufacturerList:[]
    }
  },

  created() {
    this.getSettingTable().then(() => {
      this.$nextTick(() => {
        this.setTableHeight()
        if(this.$route.query && this.$route.query.preparationName){
          // 从其他页面路由传参制剂名称，快速搜索
          this.queryParams.preparationName=this.$route.query.preparationName
        }
        if(this.$route.query && this.$route.query.preparationId){
          // 从其他页面路由传参制剂id，快速搜索
          this.queryParams.preparationId=this.$route.query.preparationId
        }
        if(this.$route.query && this.$route.query.specId){
          // 从其他页面路由传参制剂规格，快速搜索
          this.queryParams.specId=this.$route.query.specId
        }
        if(this.$route.query && this.$route.query.specDesc){
          // 从其他页面路由传参制剂规格，快速搜索
          this.queryParams.specDesc=this.$route.query.specDesc
        }
        if(this.queryParams.preparationName){
          //如果 preparationName有值，代表从制剂列表跳转过来的，先查询制剂规格列表，再查询药品列表
          getSpecInfo(this.queryParams.preparationId).then(res=>{
            res.data.forEach(item=>{
              if(item.descList ){
                item.descList.forEach(descItem=>{
                  if(descItem.isDefault==true){
                    item.specDesc=descItem.specDesc
                  }
                })
              }
              if(item.isDefault){
                this.preparationSpecList.push(item)
              }
            })
            //页面初始化完毕，默认触发一次按照更新时间排序
            this.$refs.refTable.sort('id','descending');
          })
        }else{
          //页面初始化完毕，默认触发一次按照更新时间排序
          this.$refs.refTable.sort('id','descending');
        }


      })
    })
    //获取状态数据字典
    getConcept(this.conceptConst.PUB_DATA_STATUS, 0).then(result => {
      this.statusOptions = result
    })
    //获取引用资源类型
    getResourceCategory().then(result => {
      this.resourceTypeOption = result
    })
    this.initAllDict()
  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this=this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight');
    }
    /*监听编辑页面发送的事件，执行相应的函数*/
    AcrossUtil.$on('tableReload', function (param) {
      _this.selectDrugProdList()
    })

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300);
    })
  },
  methods: {
    /*设置表格的高度*/
    setTableHeight(){
      // 获取高度值 （内容高+padding+边框）
      if(this.$refs.headerQuery){
        let formHeight = this.$refs.headerQuery.offsetHeight
        this.tableHeight = window.innerHeight - formHeight-140
      }

    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.isResult = true
      this.queryParams.pageNum = 1
      this.selectDrugProdList()
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const drugProdId = row.id
      this.$router.push('/drugProd/edit/' + drugProdId)
    },
    /** 复制按钮操作 */
    handleCopy(row) {
      const drugProdId = row.id
      this.$router.push('/drugProd/copyEdit/' + drugProdId)
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const id = row.id
      let that = this
      this.$confirm('确认要删除' + '"[' + row.id + ']-' + row.genericName + '"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        //del(id)
        del(id).then(response => {
          that.msgSuccess('删除成功')
          that.selectDrugProdList()
        })
      })
    },
    /** 新增按钮操作 */
    handleAdd() {
      // this.$router.push('/substance/edit/')
      this.$router.push('/drugProd/edit/')
    },
    /** 修改别名、批准文号、药品成分、包装药品按钮 */
    handleEdit(row, type, title) {
      this.formDisplayData = {
        base: false,
        alias: false,
        approvalNum: false,
        specDes: false,
        specIngredient: false,
        pdProd: false,
        title: ''
      }
      this.formDisplayData[type] = true
      this.formDisplayData.title = title+"   ["+row.alias+"]"
      info(row.id).then(response => {
        this.dkbDpData = response.data
        this.dpEditDialogVisible = true
      })
    },
    /** 查询药品列表 */
    selectDrugProdList() {
      this.loading = true
      let params={}
      Object.assign(params, this.queryParams)
      params.substanceIds=this.queryParams.substanceIds.toString()
      list(params).then(response => {
        this.queryParams.total=response.total
        setCache("drugProdQueryParams",this.queryParams)
        setCache("drugProdPageData",response.rows)
        this.drugList = response.rows
        this.transitionTableData()
        this.total = response.total
        this.loading = false
      })
    },
    /**药品名称检索*/
    querySearchAsync(queryString, cb) {
      if (!isEmpty(queryString)) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          selectDrugProdNameList(queryString.trim()).then(response => {
            cb(response.data)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    /**药物名称检索*/
    queryDrugName(queryString, cb) {
      if (!isEmpty(queryString)) {
        setTimeout(() => {
          selectDrugNameList({queryStr:queryString.trim()}).then(response => {
            cb(response.data)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    /**制剂名称检索*/
    queryPreparationName(queryString, cb) {
      if (!isEmpty(queryString)) {
        setTimeout(() => {
          selectPreparationNameList(queryString.trim()).then(response => {
            cb(response.data)
          })
        }, 200)
      } else {
        cb([])
      }
    },
    /*对获取到的表格数据预处理*/
    transitionTableData() {
      this.drugList.forEach((item) => {
        //药物名称
        item.drugName = item.dkbDrugInfo ? item.dkbDrugInfo.drugName : ''
        //制剂名称
        item.preparationName = item.dkbPpInfo ? item.dkbPpInfo.preparationName : ''
        //制剂剂型
        item.preparationForm = item.dkbPpInfo ? item.dkbPpInfo.preparationForm : ''
        //制剂规格描述
        item.preparationSpecDesc = item.dkbPpInfo ? item.dkbPpInfo.preparationSpecDesc : ''
        //中西草标志
        item.drugClassify = item.dkbDrugInfo ? item.dkbDrugInfo.drugClassify : ''
        //药品别名
        if (item.dkbDpAliasList) {
          item.dkbDpAliasList.forEach((aliasItem) => {
            if (aliasItem.isPrimary && aliasItem.isPrimary == '1') {
              item.alias = aliasItem.alias
              return
            }
          })
        }
        //批准文号
        item.approvalNum=''
        if (item.dkbDpApprovalNumList) {
          item.dkbDpApprovalNumList.forEach((approvalNumItem) => {
            if (approvalNumItem.approvalNum) {
              item.approvalNum += approvalNumItem.approvalNum+";"
            }
          })
          item.approvalNum=item.approvalNum.substr(0,item.approvalNum.length-1)
        }
        //规格描述
        item.specDesc=''
        if (item.dkbDpSpecDescList) {
          item.dkbDpSpecDescList.forEach((specDescItem) => {
            if (specDescItem.specDesc) {
              item.specDesc+=specDescItem.specDesc+";"
            }
          })
          item.specDesc=item.specDesc.substr(0,item.specDesc.length-1)
        }
        //本位码
        item.standardCode=''
        if (item.dkbDpStandardCodeList) {
          item.dkbDpStandardCodeList.forEach((standardCodeItem) => {
            if (standardCodeItem.standardCode) {
              item.standardCode += standardCodeItem.standardCode+";"
            }
          })
          item.standardCode=item.standardCode.substr(0,item.standardCode.length-1)
        }

        //生产企业
        item.manufacturerName=''
        if (item.dkbDpManufacturerList) {
          item.dkbDpManufacturerList.forEach((manufacturerItem) => {
            if (manufacturerItem.manufacturerName) {
              item.manufacturerName += manufacturerItem.manufacturerName+";"
            }
          })
          item.manufacturerName=item.manufacturerName.substr(0,item.manufacturerName.length-1)
        }

      })
    },
    /* 展开表格行事件*/
    expandTableRow(row, expandedRows) {
      let that=this
      let table=this.$refs.refTable
      expandedRows.forEach(function(item){
        if(item.id==row.id){
          //获取药品包含的包装药品信息
          info(row.id).then(response => {
            if (response.data && response.data.dkbPdProdList) {
              row.dkbPdProdList = response.data.dkbPdProdList
              //展开行
              table.toggleRowExpansion(item,true)
            } else {
              that.msgError('获取包装药品信息失败')
            }
          })
        }else{
          //关闭行
          // 这行可以限制表格每次只能展开一行，暂时关闭这个限制了，表格可以展开多行
          // table.toggleRowExpansion(item)
        }
      })



    },
    /*药品状态修改*/
    handleStatusChange(row) {
      this.statusDialogVisible = true
      this.editRow = row

    },
    /* 药品状态修改弹窗关闭事件*/
    statusDialogClose() {
      this.statusDialogVisible = false
      this.editRow.status = this.editRow.status === '0' ? '1' : '0'
    },
    /* 药品状态修改弹窗-确定按钮点击事件*/
    statusDialogConfirm() {
      let row = this.editRow
      this.statusDialogVisible = false
      let text = row.status === '0' ? '启用' : '停用'
      updateStatus(row.id, row.status, this.activePkgDrugProd).then(response => {
        this.msgSuccess(text + '成功')
        this.selectDrugProdList()
        //关闭表格所有展开行展开行
        this.drugTableExpandRowIds=[]



      }).catch(function() {
        row.status = row.status === '0' ? '1' : '0'
      })
    },
    /*处理子组件数据修改后的事件回调*/
    handleDpEdit() {
      //console.log('数据变化了')
      this.editFlag=true
    },
    /*处理子组件保存成功后的回调事件*/
    handleDpEditFinish() {
      this.dpEditDialogVisible = false
      //关闭表格所有展开行展开行
      this.drugTableExpandRowIds=[]
      //重新加载表格
      this.selectDrugProdList()
    },
    /*初始化全部字典数据*/
    initAllDict() {
      /*获取语言概念字典*/
      getConcept(conceptConst.PUB_LANG, 0).then(result => {
        this.languageConcep = result
      })

      /*获取概念进口国产标识字典*/
      getConcept(conceptConst.DKB_DRUG_IMPORT_CLASS, 0).then(result => {
        this.domesticOrImportDict = result
      })

      /*获取药物成分类型字典*/
      getConcept(conceptConst.DKB_DRUG_INGREDIENT_TYPE, 0).then(result => {
        this.drugIngredientTypeDict = result
      })

      /*获取数量类型字典*/
      getConcept(conceptConst.PUB_MATH_OPERATOR, 0).then(result => {
        this.quantityTypeDict = result
      })

      /*获取质量单位字典*/
      getConcept(conceptConst.PUB_MASS_UNIT, 0).then(result => {
        this.quantityUnitDict = result
      })

      /*获取有效期时间单位字典*/
      getConcept(conceptConst.PUB_TIME_UNIT_EXPIRATION, 0).then(result => {
        this.timeUnitDict = result
      })

      /**规格包装单位概念域（片、粒、袋、支、瓶、盒等）*/
      getConcept(conceptConst.PUB_SPEC_PKG_UNIT, 0).then(result => {
        this.packQtyUnitDict = result
      })
    },
    /*关闭 别名、批准文号、药品成分、包装药品弹窗*/
    closeDpEditDialog(done) {
      //this.msgSuccess('关闭前回调：')
      this.$confirm('确认关闭？未保存的数据将丢失')
        .then(_ => {
          done()
        })
    },
    /*药品表格 禁用行样式*/
    drugProdTableRowClass({row, column, rowIndex, columnIndex}){
      if (row.status && row.status==='1') {
        return 'disableData-row';
      }else if(row.dkbPdProdSize==0){
        //隐藏表格展开折叠行图标
        return 'hidden-expand';
      }
      return '';
    },
    /*药品表格--单元格-- 禁用样式*/
    drugProdTableCellClass({row, column, rowIndex, columnIndex}){
      // 停用的制剂，制剂名称加删除线
      if(row.dkbPpInfo && row.dkbPpInfo.status && row.dkbPpInfo.status==='1' && column.property==='preparationName' ){
        return 'disableData-row';
      }
      return '';
    },
    // 远程搜索获取物质名称
    async remoteMethodSL (query) {
      if (!isEmpty(query)) {
        this.loading = true
        // let queryData = {}
        // queryData.queryStr = query
        let res = await selectSubstanceNameList(query)
        if (res.code === 200) {
          this.loading = false
          this.substanceList = res.data
        }
      } else {
        this.substanceList = []
      }
    },
    // 设置按钮-获取设置的数据
    async getSettingTable () {
      let _this = this
      let obj = {}
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 10)
      this.sltConfigKey = item.configKey // 药物管理-药物列表-表格配置
      this.sltConfigClassify = item.configClassify // 配置分类
      obj.configKey = this.sltConfigKey
      this.columnOptions = []
      let res = await UCgetConfig(obj)
      if (res.data  && res.data.configValue) {
        let list = JSON.parse(res.data.configValue).list
        if (list.length > 0) {
          list.forEach(function (item) {
            if (item.show==undefined || item.show==true) {
              _this.columnOptions.push(item)
            }
          })
        }else {
          _this.msgError('获取数据表格配置信息失败')
        }
      }else{
        let defaultConfig=this.tableOptions
        defaultConfig.forEach(function (item) {
          if (item.show==undefined || item.show==true) {
            _this.columnOptions.push(item)
          }
        })
      }
      _this.$nextTick(() => {
        _this.$refs.refTable.doLayout()
      })
    },
    // 设置
    async columnSettings() {
      let item = GLOBAL_CONFIGURATION_CONFIGKEY.find(item => item.id === 10)
      this.sltConfigKey = item.configKey
      this.sltConfigClassify = item.configClassify
      let _this = this
      _this.$ColumnSettings.showColSettings(
        _this.sltConfigKey,
        _this.sltConfigClassify,
        _this.tableOptions,
        _this.getSettingTable
      )
    },
    /*来源按钮点击*/
    handleResource(row){
      this.editTableRow = row
      this.resourceTableForm.list = []
      //查询获取引用资源信息
      selectReferenceInfo(tableConst.DKB_DP, row.id, '').then(response => {
        this.resourceTableForm.list = response.data
        this.resourceTableVisible = true
        if (response.data.length > 0) {
          //还原表单验证
          this.resetForm('resourceTableForm')
        }

      })
    },
    /*添加一个来源引用*/
    addResource() {
      this.resourceTableForm.list.push({
        sourceTable: tableConst.DKB_DP,
        sourceTableField: '',
        sourceTableId: this.editTableRow.id,
        resourceTypeId: null,
        resourceId: null
      })
    },
    /* 保存来源引用*/
    saveResource() {
      this.$refs['resourceTableForm'].validate(valid => {
        if (valid) {
          saveOrUpdateInfoList(this.resourceTableForm.list, this.editTableRow.id, tableConst.DKB_DP).then(response => {
            this.resourceTableVisible = false
            this.msgSuccess()
          })
        } else {
          this.msgError('请规范填写')
        }
      })
    },
    /* 引用资源名称下拉列表数据值变化*/
    resourceNameChange(value,index) {
      let name;
      this.resourceDataOption.forEach((item)=>{
        if(item.id==value){
          name=item.name
        }
      })
      this.resourceTableForm.list[index].resourceName=name
      this.resourceDataOption = []
    },
    /*引用资源名称下拉列表搜索*/
    resourceNameQuery(query, resourceTypeId) {
      if (!isEmpty(query)) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          resourceNameQuery(resourceTypeId, query.trim()).then(response => {
            this.resourceDataOption = response.data

          })
        }, 200)
      } else {
        this.options = []
      }
    },
    /*处理来源引用表格删除*/
    handleResourceDelete(index, row) {
      this.resourceTableForm.list.splice(index, 1)

    },
    /*引用资源类型--下拉列表值变化*/
    resourceTypeChange(value,index){
      this.resourceDataOption=[];
      let item=this.resourceTableForm.list[index];
      item.resourceId=undefined
      item.resourceName=undefined

    },
    /*重置表单*/
    resetForm(formName) {
      let form = this.$refs[formName]
      if (form) {
        form.resetFields()
      }
    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}){
      if(order!=null){
        order=order=='descending'?'desc':'asc';
      }else{
        order=''
      }
      this.queryParams.orderByColumn=prop
      this.queryParams.isAsc=order
      this.handleQuery()
    },
    /*鼠标右键点击事件*/
    rowContextmenu (row, column, event) {
      this.tableContextMenuVisible = false
      this.tableContextMenuVisible = true
      // 阻止右键默认行为
      event.preventDefault()
      this.$nextTick(() => {
        this.$refs.contextbutton.init(row,column,event)
      })
    },
    /* 取消鼠标监听事件 菜单栏*/
    foo() {
      this.tableContextMenuVisible = false
      document.removeEventListener('click', this.foo)
    },
    /* 处理表格右键菜单点击*/
    handleTableAction (actionName,row, column) {
      if(actionName==='修改'){
        this.handleUpdate(row)
      }else if(actionName==='复制'){
        this.handleCopy(row)
      }else if(actionName==='别名'){
        this.handleEdit(row,'alias','别名管理')
      }else if(actionName==='批准文号'){
        this.handleEdit(row,'approvalNum','批准文号管理')
      }else if(actionName==='成分'){
        this.handleEdit(row,'specIngredient','药品成分管理')
      }else if(actionName==='包装'){
        this.handleEdit(row,'pdProd','包装药品管理')
      }else if(actionName==='来源'){
        this.handleResource(row)
      }else if(actionName==='删除'){
        this.handleDelete(row)
      } else if (actionName === '查看') {
        this.handleSearchdate(row)
      } else if(actionName==='分类维护'){
        this.handleDrugCategoryEdit(row.id)
      }
    },
    /** 查看按钮操作 */
    handleSearchdate(row) {
      const drugProdId = row.id
      this.$router.push('/drugProd/view/' + drugProdId)
    },
    /*选中一个制剂名称*/
    pickPreparationName(item){
      this.queryParams.preparationId=item.code
      this.selectPreparationSpecList()
      this.handleQuery()
    },
    /*根据制剂id查询制剂规格列表*/
    async selectPreparationSpecList(){
      getSpecInfo(this.queryParams.preparationId).then(res=>{
        res.data.forEach(item=>{
          if(item.descList ){
            item.descList.forEach(descItem=>{
              if(descItem.isDefault==true){
                item.specDesc=descItem.specDesc
              }
            })
          }
          if(item.isDefault){
            this.preparationSpecList.push(item)
          }
        })
      })


    },
    isEmpty(str){
      return isEmpty(str)
    },
    // 处理分类维护
    handleDrugCategoryEdit(dataId){
      this.$refs.drugCategoryEdit.refreshDrugCategoryTree(dataId,6)
    },
    /*远程搜索厂商信息*/
    async queryManufacturer(query) {
      if (!isEmpty(query)) {
        let res = await selectManufacturerNameByKey(query.trim())
        if (res.code === 200) {
          res.data.forEach(item=>{
            let alias=[]
            if(item.aliasList){
              item.aliasList.forEach(aliasItem=>{
                alias.push(aliasItem.alias)
              })
            }
            item.alias=alias
          })
          this.manufacturerList = res.data
        }
      } else {
        this.manufacturerList = []
      }
    },

  }
}
</script>


<style>


.hiderow .el-table__expand-column .el-icon {
  visibility: hidden;
}

.el-table .disableData-row {
  text-decoration: line-through #f50707;
}

/*隐藏表格行的展开折叠按钮*/
.hidden-expand .el-table__expand-column .cell {
  display: none;
}

.headerClass .query-form .el-form-item__content{
  line-height: 0px;
}

</style>


<style lang="scss" scoped>



.header {
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
  min-height: 55px;
  justify-content: space-between;
  align-items: center;

  .el-form {
    .el-form-item {
      margin-bottom: 0 !important;

      ::v-deep.el-form-item__label {
        font-size: 14px;
        /* color: #333;
        font-weight: 500; */
      }
    }
  }
}

.el-main{
  padding: 6px 0px;
}

.footer {
  height: 40px!important;
}

.title {
  border-left: 5px solid #0073E9;
  padding-left: 10px;
}

.el-table__expanded-cell[class*=cell] {
  padding: 10px 10px;
}

/*解决表格内部表单元素错位*/
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}

</style>
